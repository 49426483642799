<template>
  <v-card
    style="border-radius: 13px; box-shadow: 0 1px 6px 0 rgb(78, 124, 149, 0.16); border: 1px solid rgba(48,48,47,.085)"
    class="mb-3"
  >
    <div
      :class="
        `d-flex flex-column mt-1 py-4 px-4 ${
          item.DESCRIPCIONLARGA ? 'pb-0' : 'pb-3'
        }`
      "
    >
      <span class="d-flex">
        <v-chip class="mr-auto" x-small color="primary" dark>{{
          item.CANTIDADSELECCIONADA
        }}</v-chip>
      </span>
      <strong
        ><small> {{ item.DESCRIPCIONCORTA }}</small>
      </strong>
      <strong
        ><small>
          {{ item.selectedLvl1 ? item.selectedLvl1.NAME : "" }}
        </small></strong
      >
      <strong
        ><small>
          {{ item.selectedLvl2 ? item.selectedLvl2.NAME : "" }}</small
        ></strong
      >
      <div class="d-flex flex-wrap" style="gap: 2px">
        <template v-for="(butaca, idx) in butacas">
          <v-chip
            v-if="item.IDARTICULO == butaca.IDARTICULO"
            :key="'ticket' + butaca.IDBUTACA + butaca.IDARTICULO + idx"
            small
            >{{ butaca.BUTACA.CODIGO }}</v-chip
          >
        </template>
      </div>
    </div>

    <template v-if="item.DESCRIPCIONLARGA">
      <v-card-actions :class="`${item.DESCRIPCIONLARGA ? 'pt-0' : ''}`">
        <v-spacer></v-spacer>
        <v-btn icon small @click="showMoreInfo = !showMoreInfo">
          <v-icon>{{
            showMoreInfo ? "mdi-chevron-up" : "mdi-chevron-down"
          }}</v-icon>
        </v-btn>
      </v-card-actions>
      <v-expand-transition>
        <div v-show="showMoreInfo == true">
          <v-divider></v-divider>
          <v-card-text>
            <small v-html="item.DESCRIPCIONLARGA"></small>
          </v-card-text>
        </div>
      </v-expand-transition>
    </template>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    item: Object,
  },
  data() {
    return {
      showMoreInfo: true,
    };
  },
  computed: {
    ...mapGetters({
      butacas: "getButacasList",
    }),
  },
};
</script>

<style></style>
