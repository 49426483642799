<template>
  <div id="map" ref="map"></div>
</template>

<script>
import { mapGetters } from "vuex";
import { imgService } from "@/libs/media";
export default {
  props: {
    routes: Array,
    pines: Array,
    mapType: String,
  },
  data() {
    return {
      map: null,
      directionsRenderer: null,
      directionsService: null,
      markers: [],
    };
  },
  computed: {
    ...mapGetters({
      park: "getCurrentParkByDomain",
    }),
  },
  mounted() {
    this.initMap();
  },
  methods: {
    // ver https://markus.oberlehner.net/blog/using-the-google-maps-api-with-vue/
    //https://xon5.medium.com/vue-google-maps-c16da293c71
    initMap() {
      if (this.routes.length > 0) {
        const park = this.park;
        this.directionsRenderer = new google.maps.DirectionsRenderer({
          polylineOptions: { strokeColor: this.routes[0]["COLOR"] },
        });
        this.directionsService = new google.maps.DirectionsService();
        this.map = new window.google.maps.Map(this.$refs["map"], {
          center: {
            lat: parseFloat(park["LATITUDPARQUE"]),
            lng: parseFloat(park["LONGITUDPARQUE"]),
          },
          zoom: parseFloat(park["ZOOMPREDETERMINADO"]),
          mapTypeId: "terrain",
          tilt: 0,
          heading: parseFloat(park["ROTACION"]),
          //bearing: <?php echo decodificar($_SESSION["PARQUE"][codificar("ROTACION")])?>,
          restriction: {
            latLngBounds: {
              north: parseFloat(park["NORTE"]),
              south: parseFloat(park["SUR"]),
              west: parseFloat(park["OESTE"]),
              east: parseFloat(park["ESTE"]),
            },
          },
          minZoom: parseFloat(park["ZOOMMINIMO"]),
          maxZoom: parseFloat(park["ZOOMMAXIMO"]),
          zoomControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          rotateControl: false,
          fullscreenControl: false,
          styles: [
            {
              featureType: "administrative",
              elementType: "geometry",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "poi",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "road",
              elementType: "labels.icon",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
            {
              featureType: "transit",
              stylers: [
                {
                  visibility: "off",
                },
              ],
            },
          ],
        });
        this.map.setTilt(0);
        this.map.setHeading(parseFloat(park["ROTACION"]));

        const smaMapOverlay = new google.maps.ImageMapType({
          getTileUrl: function (coord, zoom) {
            return `${imgService(park["URLREPOSITORIO"])}/${zoom}/${coord.x}/${
              coord.y
            }.png`;
          },
          tileSize: new google.maps.Size(256, 256),
        });
        this.map.overlayMapTypes.push(smaMapOverlay);
        this.directionsRenderer.setMap(this.map);

        // Rutas pines
        this.pines.forEach((pin) => {
          const marker = new google.maps.Marker({
            map: this.map,
            draggable: false,
            icon: {
              url: `${imgService(pin["URLPIN"])}`,
              scaledSize: new google.maps.Size(25, 25),
            },
            position: {
              lat: parseFloat(pin["LATITUD"]),
              lng: parseFloat(pin["LONGITUD"]),
            },
          });

          const title = `<strong>${pin.DESCRIPCION}</strong><br><span>${pin.DESCRIPCIONCORTA}</span>`;
          const infowindow = new google.maps.InfoWindow({
            content: title,
          });

          if (this.mapType) {
            marker.addListener("click", (ev) => {
              if (this.mapType == "tooltip") {
                infowindow.open(this.map, marker);
              } else if (this.mapType == "redirect") {
                // redirige a una vista de mapa
              } else if (this.mapType == "filter") {
                // redirige a la vista de mapa interactivo, pero con filtro
              }
            });
          }
        });

        // RUTAS_CAMINOS
        if (this.routes.length > 0) {
          this.calculateAndDisplayRoute();
        }

        this.directionsRenderer.setMap(this.map);
      }
    },
    calculateAndDisplayRoute() {
      let waypts = [];
      let contador = 0;
      this.routes.forEach((route, key) => {
        if (contador <= 24) {
          if (key == 0) {
            contador++;
            waypts.push({
              location: {
                lat: parseFloat(route["LATITUDHASTA"]),
                lng: parseFloat(route["LONGITUDHASTA"]),
              },
              stopover: true,
            });
          }
          if (key > 0 && key < this.routes.length - 1) {
            contador++;
            contador++;

            waypts.push({
              location: {
                lat: parseFloat(route["LATITUDDESDE"]),
                lng: parseFloat(route["LONGITUDDESDE"]),
              },
              stopover: true,
            });
            waypts.push({
              location: {
                lat: parseFloat(route["LATITUDHASTA"]),
                lng: parseFloat(route["LONGITUDHASTA"]),
              },
              stopover: true,
            });
          }
          if (key == this.routes.length - 1) {
            contador++;

            waypts.push({
              location: {
                lat: parseFloat(route["LATITUDDESDE"]),
                lng: parseFloat(route["LONGITUDDESDE"]),
              },
              stopover: true,
            });
          }
        }
      });

      this.directionsService.route(
        {
          origin: {
            lat: parseFloat(this.routes[0]["LATITUDDESDE"]),
            lng: parseFloat(this.routes[0]["LONGITUDDESDE"]),
          },
          destination: {
            lat: parseFloat(this.routes[0]["LATITUDHASTA"]),
            lng: parseFloat(this.routes[0]["LONGITUDHASTA"]),
          },
          waypoints: waypts,
          optimizeWaypoints: true,
          travelMode: google.maps.TravelMode.WALKING,
        },
        (response, status) => {
          if (status == "OK") {
            this.directionsRenderer.setDirections(response);
            this.directionsRenderer.setOptions({ suppressMarkers: true });
          }
        }
      );
    },
  },
};
</script>

<style lang="scss">
#map {
  height: 400px;
  @media screen and (max-width: 400px) {
    height: 350px;
  }
  @media screen and (max-width: 360px) {
    height: 320px;
  }
}
</style>