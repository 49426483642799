<template>
  <v-card class="card__mapa__butacas" max-width="100%">
    <Header hideAuthMenu hideLang hideSocials disabledLogoLink>
      <template #content-right>
        <div id="mapa-header"></div>
      </template>
    </Header>
    <div class="card__mapa__butacas_timer">
      <Timer></Timer>
    </div>
    <div class="card__mapa__butacas_container">
      <div id="mapa-container"></div>
      <div
        class="card__mapa__butacas_loader"
        :class="{ 'card__mapa__butacas_loader--active': loader }"
      >
        {{ loaderText[lang] }}
      </div>
    </div>
    <div
      v-if="articulosButacas.length > 0"
      class="card__mapa__butacas_selecteds"
    >
      <ul>
        <template v-for="(article, idx) in articulosButacas">
          <li :key="'mapa_' + article.IDARTICULO + idx">
            <strong>{{ article.DESCRIPCIONCORTA }}</strong>
            <ul class="pl-0">
              <template v-for="(butaca, i) in butacas">
                <li
                  v-if="article.IDARTICULO == butaca.IDARTICULO"
                  style="list-style-type: none; margin-bottom: 1px;"
                  :key="'butaca_' + article.IDARTICULO + butaca.IDBUTACA + i"
                >
                  <v-chip small>{{ butaca.BUTACA.CODIGO }}</v-chip>
                </li>
              </template>
            </ul>
          </li>
        </template>
      </ul>
    </div>
    <div class="card__mapa__butacas_legend">
      <h4>{{ legend.title[lang] }}</h4>
      <div class="card__mapa__butacas_legend--enabled">
        <svg
          clip-rule="evenodd"
          fill-rule="evenodd"
          stroke-linejoin="round"
          stroke-miterlimit="2"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="11.998"
            cy="11.998"
            fill-rule="nonzero"
            r="9.998"
            fill="var(--color-mapa-enabled)"
          />
        </svg>
        {{ legend.enabled[lang] }}
      </div>
      <div class="card__mapa__butacas_legend--disabled">
        <svg
          clip-rule="evenodd"
          fill-rule="evenodd"
          stroke-linejoin="round"
          stroke-miterlimit="2"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="11.998"
            cy="11.998"
            fill-rule="nonzero"
            r="9.998"
            fill="var(--color-mapa-disabled)"
          />
        </svg>
        {{ legend.disabled[lang] }}
      </div>
      <div class="card__mapa__butacas_legend--selected">
        <svg
          clip-rule="evenodd"
          fill-rule="evenodd"
          stroke-linejoin="round"
          stroke-miterlimit="2"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="11.998"
            cy="11.998"
            fill-rule="nonzero"
            r="9.998"
            fill="var(--color-mapa-selected)"
          />
        </svg>
        {{ legend.selected[lang] }}
      </div>
    </div>
    <div class="card__mapa__butacas_actions">
      <v-btn
        :loading="loadingContinue"
        :disabled="loadingContinue || loadingPayment"
        rounded
        text
        color="primary"
        @click="continuarCompra"
      >
        <v-icon small>mdi-cart-variant</v-icon>
        {{ actions.cancel[lang] }}
      </v-btn>
      <v-btn
        :loading="loadingPayment"
        :disabled="loadingContinue || loadingPayment"
        rounded
        color="primary"
        @click="irAPagar"
        >{{ actions.pay[lang] }}</v-btn
      >
    </div>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "../../../components/Header.vue";
import Timer from "./Timer.vue";
import panzoom from "svg-pan-zoom";
import { imgServicePark } from "@/libs/media";

export default {
  components: {
    Header,
    Timer,
  },
  props: {
    idArticulo: {
      type: [Number, String],
      default: "",
    },
    articulo: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      zoom: 0,
      maxZoom: 8,
      minZoom: 1,
      panzoomInstance: null,
      loader: false,
      legend: {
        title: {
          en: "Legend",
          es: "Leyenda",
        },
        enabled: {
          en: "Avaiable",
          es: "Disponible",
        },
        disabled: {
          en: "Unavaiable",
          es: "No Disponible",
        },
        selected: {
          en: "Selected",
          es: "Seleccionado",
        },
      },
      loaderText: {
        en: "Checking Availability",
        es: " Verificando Disponibilidad",
      },
      actions: {
        pay: {
          en: "Go to pay",
          es: "Ir a pagar",
        },
        cancel: {
          en: "Keep buying",
          es: "Seguir comprando",
        },
      },
      butacasSelected: new Map(),
      butacasAforo: [],
      loadingContinue: false,
      loadingPayment: false,
    };
  },
  computed: {
    ...mapGetters({
      lang: "getLanguage",
      payload: "getFinalPayload",
      butacas: "getButacasList",
    }),
    articulosButacas() {
      const articulosSelected = this.$store.getters.getArticlesList?.filter(
        (articulo) => {
          return this.$store.getters.getButacasList?.find(
            (butaca) => butaca.IDARTICULO == articulo.IDARTICULO
          );
        }
      );
      return articulosSelected;
    },
  },
  mounted() {
    this.cargarMapaSVG();
  },
  methods: {
    esDispositivoMovil() {
      return (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        ) ||
        (window.innerWidth <= 800 && window.innerHeight <= 600)
      );
    },
    async obtenerButacas() {
      console.log(this.payload);
      const butacas = await this.$store.dispatch("fetchHorarioButacas", {
        fechaVisita: this.payload.dateSelected,
        horaInicio: this.payload?.horarioCategoria?.HORAINICIO,
        horaFin: this.payload?.horarioCategoria?.HORAFIN,
        idCategoria: this.payload.category?.IDCATEGORIA,
        idArticulo: this.idArticulo,
      });
      this.butacasAforo = butacas;
      return butacas;
    },
    async cargarMapaSVG() {
      try {
        const uriMapa = imgServicePark(this.payload.category?.URLFOTOSVG);
        if (!uriMapa) return;

        this.loader = true;

        const response = await fetch(uriMapa);
        const html = await response.text();
        const mapaContainer = document.querySelector("#mapa-container"); //mapa-header
        const mapaHeader = document.querySelector("#mapa-header"); // mapa-container

        mapaContainer.innerHTML = html;
        mapaHeader.innerHTML = html;

        const eventsHandler = {
          haltEventListeners: [
            "touchstart",
            "touchend",
            "touchmove",
            "touchleave",
            "touchcancel",
          ],
          init: function(options) {
            var instance = options.instance,
              initialScale = 1,
              pannedX = 0,
              pannedY = 0;

            // Init Hammer
            // Listen only for pointer and touch events
            this.hammer = Hammer(options.svgElement, {
              inputClass: Hammer.SUPPORT_POINTER_EVENTS
                ? Hammer.PointerEventInput
                : Hammer.TouchInput,
            });

            // Enable pinch
            this.hammer.get("pinch").set({ enable: true });

            // Handle double tap
            this.hammer.on("doubletap", function(ev) {
              instance.zoomIn();
            });

            // Handle pan
            this.hammer.on("panstart panmove", function(ev) {
              // On pan start reset panned variables
              if (ev.type === "panstart") {
                pannedX = 0;
                pannedY = 0;
              }

              // Pan only the difference
              instance.panBy({
                x: ev.deltaX - pannedX,
                y: ev.deltaY - pannedY,
              });
              pannedX = ev.deltaX;
              pannedY = ev.deltaY;
            });

            // Handle pinch
            this.hammer.on("pinchstart pinchmove", function(ev) {
              // On pinch start remember initial zoom
              if (ev.type === "pinchstart") {
                initialScale = instance.getZoom();
                instance.zoomAtPoint(initialScale * ev.scale, {
                  x: ev.center.x,
                  y: ev.center.y,
                });
              }

              instance.zoomAtPoint(initialScale * ev.scale, {
                x: ev.center.x,
                y: ev.center.y,
              });
            });

            // Prevent moving the page on some devices when panning over SVG
            options.svgElement.addEventListener("touchmove", function(e) {
              e.preventDefault();
            });
          },

          destroy: function() {
            this.hammer.destroy();
          },
        };

        const panzoomInstance = panzoom("#mapa-container > svg", {
          oomEnabled: true,
          controlIconsEnabled: true,
          fit: 1,
          center: 1,
          customEventsHandler: eventsHandler,
        });

        this.cargarAsientosYEventos();

        this.loader = false;
      } catch (error) {
        console.log(error);
        this.loader = false;
      }
      /* const svg = document.querySelector("#mapa-container > svg"); //
      if (this.esDispositivoMovil()) {
        this.maxZoom = 8;
        this.minZoom = 3;
      }
      this.loader = true;
      setTimeout(() => {
        const panzoomInstance = panzoom(svg, {
          maxZoom: Number(this.maxZoom),
          minZoom: Number(this.minZoom),
          smoothScroll: false,
          bounds: true,
          boundsPadding: 1,
          pinchSpeed: 2,
          onTouch: () => {
            return true;
          },
        });

        

        this.panzoomInstance = panzoomInstance;
        // alert(svg.innerHTML)
        // window.pz = panzoomInstance;
        console.log(window.panzoom);
      }, 2000); */
    },
    async cargarAsientosYEventos() {
      try {
        this.loader = true;
        const butacas = await this.obtenerButacas();
        console.log("BUTACAS", butacas);
        Array.from(
          document.querySelectorAll(
            `#mapa-container svg .seat, #mapa-container svg [id^="BUTACA_"]`
          )
        ).forEach((el, idx) => {
          el?.removeEventListener("click", () => {});
          el?.removeEventListener("touchend", () => {});
          el?.classList.remove("seat");
          el?.classList.remove("enabled");
          el?.classList.remove("disabled");
          el?.classList.remove("selected");

          el.classList.add("seat");
          const butacaId = el?.getAttribute("id");
          if (this.validarButacaEnabled(butacaId)) {
            el.classList.add("enabled");
            el.addEventListener("click", (ev) => {
              this.handleButacaSelected(el, ev);
            });
            el.addEventListener("touchend", (ev) => {
              this.handleButacaSelected(el, ev);
            });
          } else {
            el.classList.add("disabled");
          }
        });
        this.cargarAsientosSeleccionadosLocal();
        this.loader = false;
      } catch (error) {
        console.log(error);
        this.loader = false;
      }
    },
    cargarAsientosSeleccionadosLocal() {
      this.butacas?.forEach((butaca) => {
        const identificador = butaca.BUTACA?.IDENTIFICADOR;
        const element = document.querySelector(
          `#mapa-container #${identificador}`
        );
        console.log(
          identificador,
          element,
          `#mapa-container #${identificador}`
        );
        // this.seleccionarButaca(element, identificador);
        this.sincronizarButacaLocal(element, identificador, { ...butaca });
      });
    },
    sincronizarButacas() {
      this.$store.commit(
        "setButacasList",
        Array.from(this.butacasSelected, ([name, value]) => ({ ...value }))
      );
      console.log(this.$store.getters.getButacasList);
    },
    sincronizarButacaLocal(el, butacaId, butacaData) {
      if (butacaData.IDARTICULO != this.idArticulo) {
        el?.classList?.add("disabled");
      }
      el?.classList?.add("selected");
      el?.classList?.remove("enabled");
      console.log(el);
      this.butacasSelected.set(butacaId, butacaData);
      console.log("Sincronizar", this.butacasSelected);
    },
    async seleccionarButaca(el, butacaId) {
      // Validar si permite combinar
      const permiteCombinar = await this.$store.dispatch(
        "validateGroup",
        this.$props.articulo
      );
      console.log({ permiteCombinar });
      if (!permiteCombinar) {
        this.mostrarError({
          errors: [],
          title: {
            en: "Important",
            es: "Importante",
          },
          subtitle: {
            en: "Dear customer, this Event does not allow combining items.",
            es:
              "Estimado cliente, el presente Evento no permite combinar artículos.",
          },
          footer: {
            en: "Please feel free to purchase your items individually.",
            es:
              "Por favor, sirvase a comprar sus artículos de forma individual.",
          },
        });
        return;
      }
      // Validar incremento maximos y mínimos
      const validated = await this.$store.dispatch(
        "incrementTicket",
        this.articulo
      );

      console.log({ validated });
      if (!validated) {
        this.mostrarError({
          errors: [],
          title: {
            en: "Maximum capacity reached",
            es: "Tope máximo alcanzado",
          },
          subtitle: {
            en:
              "Dear customer, the maximum number of tickets per person has been reached.",
            es:
              "Estimado cliente, la cantidad máxima de entradas por persona ha sido alcanzada.",
          },
          // footer: { en: "please reselect your items.", es: "Por favor, vuelva a seleccionar sus artículos." }
        });
        return;
      }

      el?.classList?.add("selected");
      el?.classList?.remove("enabled");
      this.butacasSelected.set(butacaId, {
        IDBUTACA: butacaId,
        IDARTICULO: this.$props.idArticulo,
        BUTACA: this.getDataButaca(butacaId),
      });
      console.log("Añadir", this.butacasSelected);
      this.sincronizarButacas();
    },
    deseleccionarButaca(el, butacaId) {
      el?.classList?.remove("selected");
      el?.classList?.add("enabled");
      this.butacasSelected.delete(butacaId);
      console.log("Quitar", this.butacasSelected);
      this.$store.dispatch("decrementTicket", this.articulo);
      this.sincronizarButacas();
    },
    bloquearButaca(el, butacaId) {
      el?.classList?.remove("selected");
      el?.classList?.remove("enabled");
      el?.classList?.add("disabled");
      this.butacasSelected.delete(butacaId);
      console.log("Bloquear", this.butacasSelected);
      this.sincronizarButacas();
      el?.removeEventListenner("click", () => {});
      el?.removeEventListenner("touchend", () => {});
    },
    async handleButacaSelected(targetEl, ev) {
      const butacaStringId = targetEl?.getAttribute("id");
      // const butacaId = this.getIdFromButaca(idElement);
      if (!butacaStringId) return;

      const butacaId = this.getIdFromButaca(butacaStringId);

      console.log({ butacaId, butacaStringId, butacas: this.butacasAforo });

      this.loader = true;

      const butacaResponse = await this.validarAforoButaca(butacaId);

      this.loader = false;

      if (!butacaResponse || butacaResponse?.ENABLED != 1) {
        await this.cargarMapaSVG();
        // this.bloquearButaca(targetEl, butacaStringId);
        const msgError = {
          en: `The seat ${butacaStringId} has been occupied.`,
          es: `La butaca ${butacaStringId} ha sido ocupada.`,
        };
        this.mostrarError({
          errors: [msgError],
          title: { en: "Seat not available", es: "Butaca no disponible" },
          footer: {
            en: "Please select the available seats",
            es: "Por favor, seleccione las butacas disponibles.",
          },
        });
        return; // PENDIENTE: colocar un error custom (VALIDAR SI ES NECESARIO BLOQUEAR)
      }

      // Agregar butaca
      if (!this.butacasSelected.get(butacaStringId)) {
        // Agregar butaca a lista
        this.seleccionarButaca(targetEl, butacaStringId);
      } else {
        // Quitar butaca de lista
        this.deseleccionarButaca(targetEl, butacaStringId);
      }
    },
    getDataButaca(id) {
      console.log(
        id,
        this.butacasAforo.find((butaca) => butaca.IDENTIFICADOR == id)
      );
      return JSON.parse(
        JSON.stringify(
          this.butacasAforo.find((butaca) => butaca.IDENTIFICADOR == id) ?? {}
        )
      );
    },
    getIdFromButaca(stringId = "") {
      return this.butacasAforo?.find(
        (butaca) => butaca?.IDENTIFICADOR == stringId
      )?.IDBUTACA;
    },
    validarButacaEnabled(butacaId) {
      return (
        this.butacasAforo.find((butaca) => butaca.IDENTIFICADOR == butacaId)
          ?.ENABLED == 1
      );
    },
    async validarAforoButaca(idButaca) {
      const butaca = await this.$store.dispatch("fetchHorarioButaca", {
        fechaVisita: this.payload.dateSelected,
        horaInicio: this.payload?.horarioCategoria?.HORAINICIO,
        horaFin: this.payload?.horarioCategoria?.HORAFIN,
        idCategoria: this.payload.category?.IDCATEGORIA,
        idArticulo: this.idArticulo,
        idButaca: idButaca,
      });
      return butaca;
    },
    async validarAforoSeleccion(forcedValidation = false) {
      // Solo valida si hay butacas, sino se encarga de
      if (
        this.$store.getters.getButacasList?.length == 0 &&
        !forcedValidation
      ) {
        // this.$store.commit("resetAforoButacas");
        return true;
      }
      const errors = await this.$store.dispatch("validarAforoButacasZonas");
      // PENDIENTE: si hay errores reiniciar el mapa y los articulos marcados
      // PENDIENTE: colocar un error custom (VALIDAR SI ES NECESARIO BLOQUEAR)
      console.log(errors);
      if (errors?.length > 0) {
        this.mostrarError({
          errors: errors,
          title: { en: "Capacity not available", es: "Aforo no disponible" },
          subtitle: {
            en: "Dear customer, the capacity of some items has changed.",
            es:
              "Estimado cliente, la capacidad de algunos artículos ha variado.",
          },
          footer: {
            en: "Please reselect your items.",
            es: "Por favor, vuelva a seleccionar sus artículos.",
          },
        });
      }
      // errors?.length > 0 && alert(JSON.stringify(errors));
      return errors?.length == 0;
    },
    async continuarCompra() {
      this.loadingContinue = true;
      this.loader = true;
      const validado = await this.validarAforoSeleccion();
      this.loadingContinue = false;
      this.loader = false;
      this.$emit("closeDialog");
      if (!validado) this.$store.dispatch("resetAforoButacas");
    },
    async irAPagar() {
      this.loader = true;
      this.loadingPayment = true;
      const validado = await this.validarAforoSeleccion(true);
      this.loadingPayment = false;
      this.loader = false;

      this.$emit("closeDialog");

      if (validado) {
        this.$store.dispatch("changeStep", 4);
      } else {
        this.$store.dispatch("resetAforoButacas");
      }
    },
    mostrarError({ errors = [], title, subtitle, footer }) {
      this.$store.commit("setErrorDialog", {
        errors: errors,
        title: title,
        subtitle: subtitle,
        footer: footer,
      });
      this.$store.commit("setDialogError", true);
    },
  },
};
</script>

<style lang="scss">
$map_padding_bottom: 75px;
$map_padding_x: 10px;
.card__mapa__butacas {
  position: relative;
  &_container {
    cursor: grabbing;
    padding-top: 75px;
    max-width: 100vw;
    position: relative;
    @media screen and (max-width: 500px) {
      padding-top: 61px;
    }
  }
  &_loader {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #000;
    font-weight: 600;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(232, 235, 242, 0.78);
    transition: all ease-in-out 0.25;
    visibility: hidden;
    opacity: 0;
    z-index: -1;
  }
  &_loader--active {
    visibility: visible;
    opacity: 1;
    z-index: 1;
  }
  &_timer {
    position: absolute;
    top: 80px;
    left: 5px;
    z-index: 5;
    @media screen and (max-width: 500px) {
      top: 60px;
    }
  }
  &_controls {
    position: absolute;
    bottom: $map_padding_bottom;
    right: $map_padding_x;
    z-index: 5;
    gap: 8px;
    display: flex;
    flex-flow: column nowrap;
  }
  &_legend {
    position: absolute;
    bottom: $map_padding_bottom;
    left: $map_padding_x;
    z-index: 5;
    gap: 8px;
    display: flex;
    flex-flow: column nowrap;
    padding: 10px 20px;
    background: white;
    border-radius: 4px;
    & > div {
      display: flex;
      align-items: center;
      gap: 6px;
      font-size: 14px;
    }
    svg {
      width: 18px !important;
      height: 18px !important;
    }
  }
  &_selecteds {
    position: absolute;
    bottom: $map_padding_bottom + 140px;
    left: $map_padding_x;
    z-index: 5;
    gap: 8px;
    display: flex;
    flex-flow: column nowrap;
    padding: 10px 20px;
    background: white;
    border-radius: 4px;
    ul {
      padding-left: 0 !important;
      list-style: none;
      max-height: 40vh;
      overflow-y: auto;
      & > li {
        margin-bottom: 4px;
      }
    }
    & li strong {
      font-size: 13px;
    }
  }
  &_actions {
    position: absolute;
    bottom: 0;
    height: $map_padding_bottom - 14px;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 10px;
    background: white;
    align-items: center;
  }
  #mapa-container {
    width: 100%;
    height: 100%;
    max-width: 100vw;
    height: calc(100vh - 75px - 61px);
    transition: transform 0.3s ease;
    overflow: hidden !important;
    background: #e8ebf2;

    @media screen and (max-width: 500px) {
      height: calc(100vh - 61px);
      height: calc(100vh - 61px - 61px);
    }
    svg {
      width: 100%;
      height: 100%;
      transition: transform 0.3s ease;
      z-index: 4;

      .seat,
      g[id^="BUTACA_"] {
        transition: all ease-in-out 0.25s;
        &.enabled,
        &.enabled > ellipse {
          cursor: pointer;
          fill: var(--color-mapa-enabled) !important;
          &:hover {
            fill: var(--color-mapa-enabled-hover) !important;
          }
        }
        &.disabled,
        &.disabled > ellipse {
          fill: var(--color-mapa-disabled) !important;
          cursor: not-allowed;
          pointer-events: all !important;
        }
        &.selected,
        &.selected > ellipse {
          fill: var(--color-mapa-selected) !important;
          cursor: pointer;
        }

        &.selected.disabled,
        &.selected.disabled > ellipse {
          cursor: not-allowed !important;
          fill: var(--color-primary-1) !important;
        }
      }
    }
  }
  #mapa-header {
    background: white;
    width: 57px;
    height: 57px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      max-width: 52px;
      max-height: 52px;
      height: 50px !important;
    }
    @media screen and (max-width: 500px) {
      width: 48px;
      height: 48px;
      svg {
        max-width: 44px;
        max-height: 44px;
        height: 42px !important;
      }
    }
  }
}
</style>
