<template>
  <v-stepper-content step="1">
    <v-card class="mb-4 elevation-0">
      <div class="title-card readable">{{ title[lang] }}</div>
      <p class="subtitle-card">{{ steps.step1.subtitle[lang] }}</p>
      <template v-if="step == 1">
        <v-list v-if="!tipoLista || tipoLista == 0" dense >
          <!-- <v-subheader>{{ steps.step1.title[lang] }}</v-subheader> -->
          <v-list-item-group
            :mandatory="payload.category ? true : false"
            color="primary"
            v-model="payload.category"
          >
            <!-- @click="changeCategory(item)" -->
            <v-list-item
              v-for="(item, i) in categories"
              :key="item.IDCATEGORIA"
              :value="item"
              :aria-label="`${i + 1}. ${item.DESCRIPCION}`"
              class="readable"
            >
              <v-list-item-icon>
                <v-icon>{{
                  payload.category &&
                  payload.category.IDCATEGORIA == item.IDCATEGORIA
                    ? "radio_button_checked"
                    : "radio_button_unchecked"
                }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.DESCRIPCION"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
        <v-row v-if="tipoLista == 1" class="mb-4">
          <v-col
            cols="12"
            xl="4"
            lg="6"
            md="12"
            sm="12"
            v-for="(item, i) in categories"
            :key="'cards_' + i + item.IDCATEGORIA"
          >
            <CardCategory
              :category="item"
              :isActive="
                payload.category &&
                  payload.category.IDCATEGORIA == item.IDCATEGORIA
              "
              @selectCard="onCardCategorySelect($event)"
            >
            </CardCategory>
          </v-col>
        </v-row>

      </template>
    </v-card>
    <div class="d-flex justify-content-end w-100 float-btn-steppers">
      <v-btn text v-if="steps.step1.buttonCancel" class="readable">
        {{ steps.step1.buttonCancel[lang] }}
      </v-btn>
      <v-btn
        color="primary"
        rounded
        depressed
        :disabled="!payload.category"
        class="readable"
        @click="() => changeToStep2()"
      >
        {{ steps.step1.buttonNext[lang] }}
      </v-btn>
    </div>
  </v-stepper-content>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CardCategory from "./CardCategory.vue";

export default {
  components: {
    CardCategory,
  },
  data() {
    return {
      title: {
        en: "Choose a category",
        es: "Elige una categoría",
      },
    };
  },
  computed: {
    ...mapGetters({
      step: "getStep",
      steps: "getStepsData",
      lang: "getLanguage",
      categories: "getCategoriesPark",
      payload: "getFinalPayload",
      tipoLista: "obtenerModoListadoCategorias"
    }),
  },

  beforeDestroy() {
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
  },
  mounted() {
    const { categoryId } = this.$route.params;
    if (categoryId) {
      this.$router.replace(``);
      const category = this.categories.find((item) => {
        return item.IDCATEGORIA == categoryId;
      });
      if (category) {
        this.changeCategory(category);
        this.changeToStep2();
      }
    }
  },
  methods: {
    ...mapActions({
      changeStep: "changeStep",
      changeCategory: "changeCategory",
      initTimer: "initTimer",
    }),
    async changeToStep2() {
      await this.changeStep(2);
      await this.initTimer();
    },
    async onCardCategorySelect($event) {
      this.payload.category = $event
      await this.changeToStep2();
    }
  },
};
</script>

<style></style>
