var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-simple-table',{staticClass:"mb-6 table-ticket-resume",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"readable"},[_vm._v(" "+_vm._s(_vm.stepsData.step2.tableHeaders.col1[_vm.lang])+" ")]),_c('th',{staticClass:"readable",staticStyle:{"text-align":"right"}},[_vm._v(" "+_vm._s(_vm.stepsData.step2.tableHeaders.col2[_vm.lang])+" ")]),_c('th',{staticClass:"readable",staticStyle:{"text-align":"right"}},[_vm._v(" "+_vm._s(_vm.stepsData.step2.tableHeaders.col3[_vm.lang])+" ")])])]),_c('tbody',[_vm._l((_vm.articles),function(item,idx){return [(!_vm.isForo || !!_vm.obtenerHorarioArticulo(item.IDARTICULO))?_c('tr',{key:_vm.obtenerRandomId() + idx + item.IDARTICULO,staticClass:"row__article py-3",class:{
              'row__article--disabled': !_vm.esArticuloHabilitado(item.IDARTICULO),
            }},[_c('td',[_c('span',{style:({
                  display: 'inline-flex',
                  gap: '6px',
                  alignItems: 'center',
                })},[(item.COLOR)?_c('div',{staticClass:"square__color",style:({
                    width: '20px',
                    height: '20px',
                    background: item.COLOR,
                  })}):_vm._e(),_c('h5',{staticClass:"row__article_title"},[_vm._v(_vm._s(item.DESCRIPCIONCORTA))])]),(item.DESCRIPCIONLARGA)?_c('p',[_c('small',{domProps:{"innerHTML":_vm._s(item.DESCRIPCIONLARGA)}})]):_vm._e(),_c('div',{staticClass:"article-form"},[(item.LEVELS > 1)?_c('FormArticle',{key:_vm.baseIndex + item.IDARTICULO + item.LEVELS,attrs:{"article":item},on:{"updateItem":function($event){return _vm.forceRender()}}}):_vm._e()],1)]),_c('td',{staticClass:"readable",staticStyle:{"text-align":"right"},attrs:{"aria-label":((_vm.stepsData.step2.tableHeaders.col2[_vm.lang]) + ": " + (_vm.getPriceSelected(
                  item
                )))}},[_c('Price',{key:_vm.baseIndex + 5,attrs:{"price":_vm.getPriceSelected(item)}})],1),_c('td',{staticStyle:{"text-align":"right"}},[_c('div',{staticClass:"row-btn-ticket"},[(_vm.esArticuloHabilitado(item.IDARTICULO))?[(
                      _vm.isForo &&
                        _vm.obtenerTipoSeleccionArticulo(item.IDARTICULO) == 2
                    )?[_c('v-badge',{attrs:{"content":item.CANTIDADSELECCIONADA,"value":item.CANTIDADSELECCIONADA,"color":"secondary","overlap":""}},[_c('v-btn',{attrs:{"disabled":_vm.disabledTable ||
                            item.CANTIDADSELECCIONADA ==
                              item.CANTIDADMAXIMAFINAL ||
                            _vm.loaderIncrement ||
                            _vm.validateDateSelected(),"color":"primary","small":"","rounded":""},on:{"click":function($event){return _vm.abrirMapaButacas(item)}}},[_vm._v(" "+_vm._s(_vm.btns.selectChairs[_vm.lang])+" ")])],1)]:[_c('v-btn',{staticClass:"readable",attrs:{"disabled":_vm.disabledTable || item.CANTIDADSELECCIONADA == 0,"fab":"","x-small":"","depressed":"","color":"primary","dark":"","aria-label":_vm.btns.decrement[_vm.lang]},on:{"click":function($event){return _vm.decrementTicket(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("remove")])],1),_c('span',{staticClass:"ml-2 mr-2 f-secondary"},[_vm._v(" "+_vm._s(item.CANTIDADSELECCIONADA)+" ")]),_c('v-btn',{staticClass:"readable",attrs:{"disabled":_vm.disabledTable ||
                          item.CANTIDADSELECCIONADA ==
                            item.CANTIDADMAXIMAFINAL ||
                          _vm.loaderIncrement ||
                          _vm.validateDateSelected(),"fab":"","x-small":"","depressed":"","color":"primary","dark":"","aria-label":_vm.btns.increment[_vm.lang]},on:{"click":function($event){return _vm.incrementTicket(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("add")])],1)]]:[_c('span',{style:({ fontSize: '13px' })},[_vm._v(_vm._s(_vm.btns.notAvaible[_vm.lang]))])]],2)])]):_vm._e()]})],2)]},proxy:true}])}),_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","persistent":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialogButacas),callback:function ($$v) {_vm.dialogButacas=$$v},expression:"dialogButacas"}},[(_vm.dialogButacas)?_c('MapaButacas',{attrs:{"idArticulo":_vm.articuloSelected.IDARTICULO,"articulo":_vm.articuloSelected},on:{"closeDialog":_vm.cerrarMapaButacas}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }