<template>
  <span class="price">{{ getTotal }}</span>
</template>

<script>
import { formatPE } from "@/libs/price"
export default {
  props: {
    price: String | Number,
  },
  data() {
    return {
      total: this.price,
    };
  },
  computed: {
    getTotal() {
      return this.formatPrice(this.total)
    }
  },
  methods: {
    formatPrice(price) {
      return formatPE(price);
    },
  },
};
</script>

<style>
.price {
    font-family: "Roboto" !important;
}
</style>