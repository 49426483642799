const OPTS = { style: "currency", currency: "PEN" };
const FORMATTER = new Intl.NumberFormat("es-PE", OPTS);


export const formatPE = (number) => {
    //if (!number) return "";
    if (typeof number != "number") {
        number = Number.parseFloat(number)
    };
    return FORMATTER.format(number);
}