<template>
  <v-card class="card__error" width="350" height="auto">
    <v-card-title class="text-h5">
      <h4 v-if="errorDialog.title" class="text__title">{{ errorDialog.title[lang] }}</h4>
    </v-card-title>
    <v-card-text>
      <p v-if="errorDialog.subtitle" class="text__subtitle">{{ errorDialog.subtitle[lang] }}</p>
      <ul v-if="errorDialog.errors">
        <li v-for="(item, idx) in errorDialog.errors" :key="getRandomId()+idx" class="text__error">{{ item[lang] }}</li>
      </ul>
      <p v-if="errorDialog.footer" class="mt-3 mb-0 text__footer">
        {{ errorDialog.footer[lang] }}
      </p>
    </v-card-text>
    <v-card-actions class="d-flex justify-content-center mb-4">
      <v-btn color="primary" rounded min-width="130" @click="$store.commit('setDialogError', false)">
        {{ btn[lang] }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
 
<script>
import { v4 as uuidv4 } from "uuid";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      btn: {
        en: "Accept",
        es: "Aceptar",
      },
    };
  },
  computed: {
    ...mapGetters({
      lang: "getLanguage",
      errorDialog: "obtenerErrorDialogo",
    }),
  },
  methods: {
    getRandomId() {
      return uuidv4()
    }
  }
};
</script>

<style lang="scss">
.card__error {
  .text__title {
    font-size: 19px;
  }
  .text__error,
  .text__subtitle,
  .text__footer {
    color: #444 !important;
    opacity: 1 !important;
    font-size: 13px;
  }
  .text__error {
    font-size: 13px;
  }

  .text__footer {
    margin-top: 18px !important;
  }
}
</style>
