<template>
  <v-stepper-content step="4">
    <v-card class="mb-12 elevation-0">
      
      <div class="d-flex align-items-start">
        <!-- <v-btn icon>
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn> -->
        <div>
          <div class="title-card readable">
            {{ permitFree ? titleFree[lang] : title[lang] }}
          </div>
          <p class="subtitle-card">{{ subtitle[lang] }}</p>
        </div>
      </div>
      <div>
        <v-btn
          text
          v-if="steps.step4.buttonCancel"
          @click="removeStep4"
          class="readable pl-0 pr-1"
        >
          <v-icon>mdi-chevron-left</v-icon>
          {{ steps.step4.buttonCancel[lang] }}
        </v-btn>
      </div>
      <hr class="separator" />
      <FormSale ref="formPayment"></FormSale>
    </v-card>

    <!-- <div class="d-flex justify-content-start w-100">
      <v-btn
        text
        v-if="steps.step4.buttonCancel"
        @click="removeStep4"
        class="readable"
      >
        <v-icon>mdi-chevron-left</v-icon>
        {{ steps.step4.buttonCancel[lang] }}
      </v-btn>
    </div> -->
  </v-stepper-content>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import FormSale from "./FormSale.vue";
export default {
  components: {
    FormSale,
  },
  data() {
    return {
      title: {
        en: "Purchase information",
        es: "Información de la compra",
      },
      subtitle: {
        en: "Correctly complete all the fields of the form shown below",
        es:
          "Completa correctamente todos los campos del formulario que se muestra debajo",
      },
      titleFree: {
        en: "Registration information",
        es: "Información de registro",
      },
    };
  },
  computed: {
    ...mapGetters({
      steps: "getStepsData",
      lang: "getLanguage",
      permitFree: "validateFree",
    }),
  },
  methods: {
    ...mapActions({
      removeStep4: "removeStep4",
      changeStep: "changeStep",
      sendForm: "sendForm",
    }),
  },
};
</script>

<style></style>
