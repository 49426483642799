import { render, staticRenderFns } from "./Step2.vue?vue&type=template&id=354945a5&"
import script from "./Step2.vue?vue&type=script&lang=js&"
export * from "./Step2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
installComponents(component, {VBtn,VCard,VIcon,VSelect,VStepperContent})
