<template>
  <v-stepper-content step="3">
    <v-card class="mb-12 elevation-0">
      <div class="title-card readable">
        {{ routes.length > 0 ? routesTitle[lang] : defaultTitle[lang] }}
      </div>
      <p class="subtitle-card readable">
        {{ routes.length > 0 ? routesSubtitle[lang] : defaultSubtitle[lang] }}
      </p>
      <div v-if="routes.length > 0" class="map-container">
        <v-btn-toggle :value="routeSelected" color="tertiary">
          <v-btn
            v-for="(item, idx) in routes"
            :key="idx + item.IDRUTA"
            @click="() => changeRouteSelected(item.IDRUTA)"
            :value="item.IDRUTA"
            class="readable"
          >
            <span>{{ item.DESCRIPCION }}</span>
          </v-btn>
        </v-btn-toggle>
        <div v-if="routesCordinated.length > 0 && routes.length > 0">
          <Map
            :routes="routesCordinated"
            :pines="pinesRoutes"
            :key="mapBaseIndex"
          ></Map>
        </div>
        <div v-if="getRoute">
          <p class="text-medium fw-500 pt-2 mb-0">
            <small>{{ getRoute.DESCRIPCIONCORTA }}</small>
          </p>
        </div>
      </div>
      <v-list dense v-if="step == 3">
        <v-list-item color="tertiary" class="bg-tertiary" dark>
          <v-list-item-content>
            <v-list-item-title class="fw-600 readable">{{
              tableSchedule.col1[lang]
            }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <div class="aforo-info d-flex">
              <span class="title-type readable">
                {{ tableSchedule.col2[lang] }}
              </span>
              <!--<span class="divider">/</span>
              <span class="title-type readable">
                {{ tableSchedule.col3[lang] }}
              </span>-->
            </div>
          </v-list-item-action>
        </v-list-item>
        <v-list-item-group
          ref="scheduleGroup"
          :mandatory="payload.schedule ? true : false"
          color="primary"
          :value="payload.schedule"
          @change="changeSchedule"
        >
          <template v-for="(item, i) in schedules">
            <v-list-item
              :key="item.HORAINICIO + i"
              :value="`${i + 1}-${item.HORAINICIO}-${item.HORAFIN}`"
              :disabled="item.CANTIDAD == 0 || item.ENABLED == '0'"
              class="readable"
            >
              <v-list-item-content>
                <v-list-item-title
                  >{{ item.HORAINICIO }} - {{ item.HORAFIN }}</v-list-item-title
                >
              </v-list-item-content>
              <v-list-item-action>
                <div class="aforo-info d-flex">
                  <span class="content">
                    <small style="white-space: nowrap !important;" v-if="item.CANTIDAD == 0">{{ tableSchedule.notAvaible[lang] }}</small>
                    <span v-else>{{ item.CANTIDAD  }}</span>
                  </span>
                  <!--<span class="divider">/</span>
                  <span class="content">
                    {{ item.AFORO }}
                  </span>-->
                </div>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
    </v-card>

    <div class="d-flex justify-content-end w-100 float-btn-steppers">
      <v-btn
        text
        rounded
        v-if="steps.step3.buttonCancel"
        @click="removeStep3"
        class="readable"
      >
        <v-icon>mdi-chevron-left</v-icon>
        {{ steps.step3.buttonCancel[lang] }}
      </v-btn>
      <v-btn
        :disabled="!payload.schedule || loadingBtn"
        :loading="loadingBtn"
        class="ml-1 readable"
        color="primary"
        rounded
        depressed
        @click="nextStep"
      >
        {{ steps.step3.buttonNext[lang] }}
      </v-btn>
    </div>
  </v-stepper-content>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Map from "./Map.vue";

export default {
  components: {
    Map
  },
  data() {
    return {
      defaultTitle: {
        en: "Hours and capacity",
        es: "Horarios y aforo"
      },
      defaultSubtitle: {
        en: "Choose your preferred time",
        es: "Elige tu horario de preferencia"
      },
      routesTitle: {
        en: "Routes, schedules and capacity",
        es: "Rutas, Horarios y aforo"
      },
      routesSubtitle: {
        en: "Choose your preferred route and schedule",
        es: "Elige tu ruta y horario de preferencia"
      },
      tableSchedule: {
        col1: {
          en: "Schedule",
          es: "Horario"
        },
        col2: {
          en: "Available capacity",
          es: "Capacidad disponible"
        },
        col3: {
          en: "Total capacity",
          es: "Capacidad total"
        },
        notAvaible: {
          en: "Not available",
          es: "AGOTADO",
        },
      },
      loadingBtn: false,
      routesCordinated: [],
      pinesRoutes: [],
      mapBaseIndex: 12562
    };
  },
  computed: {
    ...mapGetters({
      steps: "getStepsData",
      step: "getStep",
      lang: "getLanguage",
      payload: "getFinalPayload",
      scheduleSelected: "getScheduleSelected",
      routeSelected: "getRouteSelected",
      schedules: "getSchedules",
      routes: "getAllRoutes"
    }),
    getRoute() {
      return this.routes.find(route => route.IDRUTA == this.routeSelected);
    }
  },
  watch: {
    routeSelected(routeId) {
      if (routeId) {
        this.loadCordinateRoute(routeId);
      }
    },
    schedules() {
      if (this.schedules.length === 1) {
        if (this.schedules[0].CANTIDAD != 0 && this.schedules[0].ENABLED != 0) {
          this.activateItemSchecule(this.schedules[0]);
        }
      }
    }
  },
  methods: {
    ...mapActions({
      changeStep: "changeStep",
      removeStep3: "removeStep3",
      changeSchedule: "changeSchedule",
      validateAforo: "validateAforo",
      changeRouteSelected: "changeRouteSelected",
      fetchCordinateRoutes: "fetchCordinateRoutes",
      fetchPinesRoute: "fetchPinesRoute",
      generateSnackbarError: "generateSnackbarError"
    }),
    activateItemSchecule(item) {
      this.changeSchedule(`${1}-${item.HORAINICIO}-${item.HORAFIN}`);
    },
    async loadCordinateRoute(routeId) {
      this.pinesRoutes = await this.fetchPinesRoute(routeId);
      this.routesCordinated = await this.fetchCordinateRoutes(routeId);
      this.mapBaseIndex += 15;
    },
    async nextStep() {
      if ((this.payload.schedule || false) === false) {
        this.generateSnackbarError({
          en: "Select a time to continue",
          es: "Seleccione un horario para continuar"
        });
        return;
      } else {
        this.loadingBtn = true;
        const validated = await this.validateAforo(this.scheduleSelected);
        this.loadingBtn = false;
        if (!validated) {
          this.generateSnackbarError({
            en: "The number of people cannot exceed the available capacity",
            es:
              "La cantidad de personas no puede ser superior al aforo disponible"
          });
          return;
        }
        this.changeStep(4);
      }
    }
  }
};
</script>

<style lang="scss">
.aforo-info {
  display: flex;
  & span {
    font-family: $primary-font;
    font-size: 14px;
    font-weight: 500;
    display: inline-block;
    min-width: 60px;
    max-width: 60px;
    text-align: center;
    @media screen and (max-width: 350px) {
      min-width: 40px !important;
    }
  }
  span.divider {
    max-width: 10px;
    display: flex;
    justify-content: center;
    @media screen and (max-width: 400px) {
      min-width: 20px !important;
    }
    @media screen and (max-width: 340px) {
      min-width: 15px !important;
    }
  }
  span.title-type {
    font-size: 8px;
  }
}
</style>
