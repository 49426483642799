<template>
  <div>
    <v-simple-table class="mb-6 table-ticket-resume">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="readable">
              {{ stepsData.step2.tableHeaders.col1[lang] }}
            </th>
            <th class="readable" style="text-align: right">
              {{ stepsData.step2.tableHeaders.col2[lang] }}
            </th>
            <th class="readable" style="text-align: right">
              {{ stepsData.step2.tableHeaders.col3[lang] }}
            </th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(item, idx) in articles">
            <tr
              v-if="!isForo || !!obtenerHorarioArticulo(item.IDARTICULO)"
              :key="obtenerRandomId() + idx + item.IDARTICULO"
              class="row__article py-3"
              :class="{
                'row__article--disabled': !esArticuloHabilitado(item.IDARTICULO),
              }"
              
            >
              <td >
                <span
                  :style="{
                    display: 'inline-flex',
                    gap: '6px',
                    alignItems: 'center',
                  }"
                >
                  <div
                    v-if="item.COLOR"
                    :style="{
                      width: '20px',
                      height: '20px',
                      background: item.COLOR,
                    }"
                    class="square__color"
                  ></div>
                  <h5 class="row__article_title">{{ item.DESCRIPCIONCORTA }}</h5>
                </span>
                <p v-if="item.DESCRIPCIONLARGA">
                  <small v-html="item.DESCRIPCIONLARGA"></small>
                </p>
                <div class="article-form">
                  <FormArticle
                    :key="baseIndex + item.IDARTICULO + item.LEVELS"
                    v-if="item.LEVELS > 1"
                    :article="item"
                    @updateItem="forceRender()"
                  ></FormArticle>
                </div>
              </td>
              <td
                style="text-align: right"
                class="readable"
                :aria-label="
                  `${stepsData.step2.tableHeaders.col2[lang]}: ${getPriceSelected(
                    item
                  )}`
                "
              >
                <Price
                  :key="baseIndex + 5"
                  :price="getPriceSelected(item)"
                ></Price>
              </td>
              <td style="text-align: right">
                <div class="row-btn-ticket">
                  <!-- PARA LOS QUE TIENEN IDFORO -->
                  <template v-if="esArticuloHabilitado(item.IDARTICULO)">
                    <template
                      v-if="
                        isForo &&
                          obtenerTipoSeleccionArticulo(item.IDARTICULO) == 2
                      "
                    >
                      <v-badge
                        :content="item.CANTIDADSELECCIONADA"
                        :value="item.CANTIDADSELECCIONADA"
                        color="secondary"
                        overlap
                      >
                        <v-btn
                          :disabled="
                            disabledTable ||
                              item.CANTIDADSELECCIONADA ==
                                item.CANTIDADMAXIMAFINAL ||
                              loaderIncrement ||
                              validateDateSelected()
                          "
                          color="primary"
                          small
                          rounded
                          @click="abrirMapaButacas(item)"
                        >
                          {{ btns.selectChairs[lang] }}
                          <!-- {{ item.CANTIDADSELECCIONADA > 0 ? `(${item.CANTIDADSELECCIONADA})` : '' }} -->
                        </v-btn>
                      </v-badge>
                    </template>
                    <!-- PARA LOS QUE NO TIENEN IDFORO -->
                    <template v-else>
                      <v-btn
                        :disabled="
                          disabledTable || item.CANTIDADSELECCIONADA == 0
                        "
                        @click="decrementTicket(item)"
                        fab
                        x-small
                        depressed
                        color="primary"
                        dark
                        class="readable"
                        :aria-label="btns.decrement[lang]"
                      >
                        <v-icon small>remove</v-icon>
                      </v-btn>
                      <span class="ml-2 mr-2 f-secondary">
                        {{ item.CANTIDADSELECCIONADA }}
                      </span>
                      <v-btn
                        :disabled="
                          disabledTable ||
                            item.CANTIDADSELECCIONADA ==
                              item.CANTIDADMAXIMAFINAL ||
                            loaderIncrement ||
                            validateDateSelected()
                        "
                        @click="incrementTicket(item)"
                        fab
                        x-small
                        depressed
                        class="readable"
                        color="primary"
                        dark
                        :aria-label="btns.increment[lang]"
                      >
                        <v-icon small>add</v-icon>
                      </v-btn>
                    </template>
                  </template>
                  <template v-else>
                    <span :style="{ fontSize: '13px' }">{{
                      btns.notAvaible[lang]
                    }}</span>
                  </template>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-simple-table>
    <v-dialog
      v-model="dialogButacas"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
    >
      <MapaButacas
        v-if="dialogButacas"
        :idArticulo="articuloSelected.IDARTICULO"
        :articulo="articuloSelected"
        @closeDialog="cerrarMapaButacas"
      ></MapaButacas>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Price from "./Price.vue";
import FormArticle from "./FormArticle.vue";
import M from "minimatch";
import MapaButacas from "./MapaButacas.vue";
import { v4 as uuidv4 } from "uuid";

export default {
  props: {
    hasPurchasePreview: [Number, String],
    disabledTable: Boolean,
  },
  components: {
    Price,
    FormArticle,
    MapaButacas,
  },
  data() {
    return {
      btns: {
        increment: {
          en: "Increment",
          es: "Aumentar",
        },
        decrement: {
          en: "Decrement",
          es: "Disminuir",
        },
        selectChairs: {
          en: "Select seats",
          es: "Elegir asiento(s)",
        },
        selectZones: {
          en: "Choose areas",
          es: "Elegir zona(s)",
        },
        notAvaible: {
          en: "Not available",
          es: "AGOTADO",
        },
      },
      dialogButacas: false,
      articuloSelected: {},
    };
  },
  computed: {
    ...mapGetters({
      dateSelected: "getDateSelected",
      articles: "getArticlesList",
      stepsData: "getStepsData",
      finalPayload: "getFinalPayload",
      lang: "getLanguage",
      loaderIncrement: "getBtnIncrement",
      baseIndex: "getBaseIndex",
      isForo: "contieneIdForo",
      horariosArticulo: "obtenerHorariosCategoriaValidacion",
    }),
  },
  methods: {
    obtenerRandomId() {
      return uuidv4();
    },
    obtenerHorarioArticulo(idArticulo) {
      return this.horariosArticulo.find(
        (horario) => horario.IDARTICULO == idArticulo
      );
    },
    obtenerTipoSeleccionArticulo(idArticulo) {
      return this.obtenerHorarioArticulo(idArticulo)?.TIPOZONA ?? 0;
    },
    esArticuloHabilitado(idArticulo) {
      if (!this.isForo) return true;
      return this.horariosArticulo.find(
        (horario) => horario?.IDARTICULO == idArticulo && horario?.ENABLED == 1
      );
      // return this.obtenerHorarioArticulo(idArticulo)?.ENABLED == 1;
    },
    validateDateSelected() {
      if (this.hasPurchasePreview == 0) {
        const fecha = this.dateSelected;
        if (fecha) {
          return false;
        }
        return true;
      }
      return false;
    },
    forceRender() {
      this.$store.commit("updateBaseIndex");
    },
    getPriceSelected(article) {
      if (!this.finalPayload.category) return 0;
      const showPrice =
        this.finalPayload.category.MOSTRARPRECIO != "0" ? true : false;
      const allowsGroup =
        this.finalPayload.category.AGRUPAR != "0" ? true : false;

      if (article.LEVELS == 1) {
        return !allowsGroup ? article.PRECIO : 0;
      } else if (article.LEVELS == 2) {
        if (allowsGroup && showPrice) return article.PRECIO;
        return /* showPrice ||  */ article.selectedLvl1
          ? article.selectedLvl1.PRECIO
          : 0;
      } else {
        if (allowsGroup && showPrice) return article.PRECIO;
        return /* showPrice ||  */ article.selectedLvl2
          ? article.selectedLvl2.PRECIO
          : 0;
      }
    },
    abrirMapaButacas(item) {
      // PENDIENTE: Validar aforo antes de abrir el mapa
      this.articuloSelected = item;
      this.$store.commit("activeLoader", true);
      setTimeout(() => {
        this.dialogButacas = true;
        this.$store.commit("activeLoader", false);
      }, 1000);
    },
    cerrarMapaButacas() {
      this.dialogButacas = false;
    },
    async incrementTicket(item, butaca = null) {
      const tipoSeleccion = this.obtenerTipoSeleccionArticulo(item.IDARTICULO);
      console.log({ tipoSeleccion });
      if (tipoSeleccion == 2) {
        // Es una selección de Asientos (Añadir butaca)
        console.log("Validar Aforo por asientos");
      }
      const permiteCombinar = await this.$store.dispatch("validateGroup", item);
      console.log({ permiteCombinar });
      if (!permiteCombinar) {
        this.mostrarError({
          errors: [],
          title: {
            en: "Important",
            es: "Importante",
          },
          subtitle: {
            en: "Dear customer, this Event does not allow combining items.",
            es:
              "Estimado cliente, el presente Evento no permite combinar artículos.",
          },
          footer: {
            en: "Please feel free to purchase your items individually.",
            es:
              "Por favor, sirvase a comprar sus artículos de forma individual.",
          },
        });
        return;
      }
      const permiteIncrementar = await this.$store.dispatch(
        "incrementTicket",
        item
      );
      if (!permiteIncrementar) {
        this.mostrarError({
          errors: [],
          title: {
            en: "Maximum capacity reached",
            es: "Tope máximo alcanzado",
          },
          subtitle: {
            en:
              "Dear customer, the maximum number of tickets per person has been reached.",
            es:
              "Estimado cliente, la cantidad máxima de entradas por persona ha sido alcanzada.",
          },
          // footer: { en: "Please reselect your items.", es: "Por favor, vuelva a seleccionar sus artículos." }
        });
        return;
      }
    },
    decrementTicket(item, butaca = null) {
      const tipoSeleccion = this.obtenerTipoSeleccionArticulo(item.IDARTICULO);
      console.log({ tipoSeleccion });
      if (tipoSeleccion == 2) {
        // Es una selección de Asientos  (Eliminar Butaca)
        console.log("Validar Aforo por asientos");
      }
      this.$store.dispatch("decrementTicket", item);
    },
    mostrarError({ errors = [], title, subtitle, footer }) {
      this.$store.commit("setErrorDialog", {
        errors: errors,
        title: title,
        subtitle: subtitle,
        footer: footer,
      });
      this.$store.commit("setDialogError", true);
    },
  },
};
</script>

<style lang="scss">
.table-ticket-resume {
  thead {
    @media screen and (max-width: 506px) {
      display: none;
    }
  }
  tbody {
    tr {
      td {
        min-height: 48px;
        height: 55px;
        vertical-align: middle;
        padding: 12px 16px !important;
      }
    }
    @media screen and (max-width: 506px) {
      display: flex;
      flex-flow: column nowrap;
      tr {
        display: flex;
        flex-flow: column nowrap;
        border-bottom: thin solid rgba(0, 0, 0, 0.12);
        td {
          border: none !important;
          height: auto !important;
        }
      }
    }
  }
}

.row__article {
  .row__article_title {
    position: relative;
  }
  &--disabled {
    .row__article_title {
      color: #444;
    }
    .row__article_title::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translate(0, -50%);
      width: 100%;
      height: 1px;
      background: #444;
    }
  }
}

.article-form {
  width: 190px;
  @media screen and (max-width: 506px) {
    width: 100%;
  }
}
.row-btn-ticket {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* @media screen and (max-width: 506px) {
    flex-flow: column-reverse nowrap;
    align-items: center;
  } */
}
</style>
