<template>
  <div v-if="dialogError" class="dialog__overlay__error">
    <CardError></CardError>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CardError from './CardError.vue'

export default {
  components: {
    CardError
  },
  computed: {
    ...mapGetters({
      dialogError: "obtenerEstadoDialogoError"
    })
  }
}
</script>

<style lang="scss">
.dialog__overlay__error {
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0,0,0,.6);
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>