<template>
  <div class="glider" id="swiper-dates">
    <template v-for="(item, idx) in dates">
      <!-- CALENDAR -->
      <v-dialog
        :key="item.FECHA + idx"
        v-if="item.FECHA_FORMATO == '+'"
        ref="dialogDate"
        v-model="modal"
        :return-value.sync="date"
        persistent
        width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <div class="glider-item">
            <button
              v-bind="attrs"
              v-on="on"
              class="swiper-date f-secondary readable"
            >
              <span>{{ replaceSpace(item.FECHA_FORMATO) }}</span>
            </button>
            <v-text-field
              class="d-none"
              v-model="date"
              label="Picker in dialog"
              prepend-icon="mdi-calendar"
              readonly
            ></v-text-field>
          </div>
        </template>
        <v-date-picker
          v-model="date"
          scrollable
          :allowed-dates="getAllowedDates"
          :min="getMinDate"
          :max="getMaxDate"
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" class="readable" @click="modal = false">
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            class="readable"
            @click="
              $refs.dialogDate[0].save(date);
              changeDateToStore(date);
            "
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-dialog>

      <!-- BTN FECHA -->
      <div class="glider-item" v-else :key="item.FECHA + idx">
        <button
          :disabled="item.ENABLED == 0"
          :value="item.FECHA"
          type="button"
          :class="
            `swiper-date  f-secondary readable ${
              item.FECHA == payload.dateSelected ? 'v-btn--active' : ''
            }`
          "
          :aria-label="item.FECHA"
          @click.prevent="
            ev => {
              changeDate(item.FECHA);
            }
          "
        >
          <span>{{ replaceSpace(item.FECHA_FORMATO) }}</span>
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
export default {
  props: {
    dates: Array
  },
  data() {
    return {
      defaultDate: moment().format("YYYY-MM-DD"),
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      modal: false
    };
  },
  computed: {
    ...mapGetters({
      payload: "getFinalPayload",
      calendar: "getCalendarList"
    }),
    getMinDate() {
      if (!this.calendar || this.calendar.length == 0) return this.defaultDate;
      return this.formatDateCalendar(this.calendar[0].FECHA);
    },
    getMaxDate() {
      if (!this.calendar || this.calendar.length == 0) return this.defaultDate;
      return this.formatDateCalendar(
        this.calendar[this.calendar.length - 1].FECHA
      );
    }
  },
  mounted() {
    new Glider(document.querySelector(".glider"), {
      slidesToShow: 5.5,
      slidesToScroll: 4,
      draggable: true,
      dragVelocity: 1.5,
      resizeLock: false
      //itemWidth: 70,
    });

    if (this.dates.length === 1) {
      if (this.dates[0].ENABLED !== 0) {
        this.changeDate(this.dates[0].FECHA);
      }
    }
  },
  methods: {
    ...mapActions({
      changeDate: "changeDate"
    }),
    replaceSpace(date = "") {
      return date.split("  ").join("\n");
    },
    formatDateCalendar(date) {
      const arr = date.split("/");
      return `${arr[2]}-${arr[1]}-${arr[0]}`;
    },
    formatDateToISO(date) {
      const arr = date.split("-");
      return `${arr[2]}/${arr[1]}/${arr[0]}`;
    },
    changeDateToStore(date) {
      const finalDate = this.formatDateToISO(date);
      this.changeDate(finalDate);
    },
    getAllowedDates(val) {
      const founded = this.calendar.findIndex(
        it => it.FECHA == this.formatDateToISO(val) && it.ENABLED == "1"
      );
      if (!founded == -1) return false;
      return true;
    }
  }
};
</script>

<style lang="scss">
#swiper-dates {
  margin-bottom: 20px;
  button {
    background: var(--color-primary-2) !important;
  }
  button:disabled {
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
    background: rgba(0, 0, 0, 0.12) !important;
    cursor: not-allowed !important;
  }
  .v-btn--active {
    background: var(--color-primary-3) !important;
    &:before {
      background: var(--color-primary-3) !important;
    }
  }
  .swiper-slide {
    display: flex !important;
    //width: 100% !important;
  }
}

.glider-item {
  min-width: 70px !important;
  width: 70px !important;
  height: 70px !important;
  margin: 0 5px;
  @media screen and(max-width: 400px) {
    width: 60px !important;
    height: 60px !important;
    margin: 0 4px;
  }
}
.swiper-date {
  width: 100% !important;
  height: 100% !important;
  background: var(--color-blue);
  border: 1px solid rgba(0, 0, 0, 0.12) !important;

  border-radius: 5px;
  color: #fff;
  //padding: 12px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    user-select: none;
    white-space: pre-wrap;
    text-align: center;
    font-size: 13px !important;
    display: block;
    @media screen and(max-width: 400px) {
      font-size: 12px !important;
    }
  }
}
</style>
