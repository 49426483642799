<template>
  <v-card class="py-5 px-5 mx-auto card-resume scroll-design mh-100 pb-0">
    <v-card-text class="h-100">
      <template v-if="park">
        <div class="d-flex flex-column justify-content-between h-100">
          <div>
            <h3 class="c-tertiary fw-900 mb-2">{{ title[lang] }}</h3>
            <img
              draggable="false"
              class="img-fluid img-resume"
              :src="getImg(park.URLFOTO)"
              alt=""
            />
            <h4 class="fw-800 readable">{{ park.DESCRIPCION }}</h4>
            <p class="text-medium f-secondary readable">{{ park.DIRECCION }}</p>
            <!-- Detalles -->
            <div class="item-selected f-secondary readable">
              <strong class="title-selected c-tertiary mb-1">
                {{ categoryTitle[lang] }}
              </strong>
              <span class="text-medium">
                {{ payload.category ? payload.category.DESCRIPCION : "" }}
              </span>
            </div>
            <hr class="separator" />
            <div class="item-selected f-secondary readable">
              <strong class="title-selected c-tertiary mb-1">
                {{ dateTitle[lang] }}
              </strong>
              <span v-if="step > 1" class="text-medium">
                {{ payload.dateSelected }}
              </span>
            </div>
            <hr class="separator" />

            <template
              v-if="
                haveAditionalProccess &&
                  !getIfGlobalAforo &&
                  payload.category &&
                  step > 2
              "
            >
              <div v-if="isForo" class="item-selected f-secondary readable">
                <strong class="title-selected c-tertiary mb-1">
                  {{ payload.category.GRUPOCATEGORIA }}
                </strong>
                <span v-if="payload.horarioCategoria" class="text-medium">
                  {{ payload.horarioCategoria.HORARIO_DESCRIPCION || `${payload.horarioCategoria.HORAINICIO} - ${payload.horarioCategoria.HORAFIN}`  }}
                </span>
              </div>
              <div v-else class="item-selected f-secondary readable">
                <strong class="title-selected c-tertiary mb-1">
                  {{ payload.category.GRUPOCATEGORIA }}
                </strong>
                <span v-if="getScheduleSelected" class="text-medium">
                  {{ getScheduleSelected.HORARIO_DESCRIPCION || `${getScheduleSelected.HORAINICIO} - ${getScheduleSelected.HORAFIN}` }}
                </span>
              </div>
              <hr class="separator" />
            </template>

            <div class="item-selected f-secondary">
              <strong class="title-selected c-tertiary mb-1 readable">
                {{ articlesTitle[lang] }}
              </strong>
              <div class="tickets-list scroll-design sm-scroll hovered ">
                <CardTicketInfo
                  v-for="(item, idx) in articles"
                  :key="idx + item.IDARTICULO"
                  :item="item"
                  class="readable"
                ></CardTicketInfo>
                <!-- <v-card
                  
                  elevation="3"
                  shaped
                  class="mb-2"
                >
                  <div class="d-flex flex-column mt-1 py-3 px-3">
                    <span class="d-flex">
                      <v-chip class="mr-auto" x-small color="primary" dark>{{
                        item.CANTIDADSELECCIONADA
                      }}</v-chip>
                    </span>
                    <strong
                      ><small> {{ item.DESCRIPCIONCORTA }}</small>
                    </strong>
                    <strong
                      ><small>
                        {{ item.selectedLvl1 ? item.selectedLvl1.NAME : "" }}
                      </small></strong
                    >
                    <strong
                      ><small>
                        {{
                          item.selectedLvl2 ? item.selectedLvl2.NAME : ""
                        }}</small
                      ></strong
                    >
                  </div>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      icon
                      @click="openInfoTicket(`show${item.IDARTICULO}`)"
                    >
                      <v-icon>{{ showMoreInfo[`show${item.IDARTICULO}`] ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                    </v-btn>
                  </v-card-actions>
                  <v-expand-transition>
                    <div v-show="showMoreInfo[`show${item.IDARTICULO}`] == true">
                      <v-divider></v-divider>
                      <v-card-text>
                        I'm a thing. But, like most politicians, he promised more than he could deliver. You won't have time for sleeping, soldier, not with all the bed making you'll be doing. Then we'll go with that data file! Hey, you add a one and two zeros to that or we walk! You're going to do his laundry? I've got to find a way to escape.
                      </v-card-text>
                    </div>
                  </v-expand-transition>
                </v-card> -->
              </div>
              <strong class="mt-4 readable"
                ><span
                  >{{ park ? park.ENTRADASDESCRIPCION : "" }}:
                  {{ tickets }}</span
                ></strong
              >
              <strong v-if="haveAditionalProccess" class="mt-4 readable"
                ><span
                  >{{ peopleDescription[lang] }}: {{ people }}</span
                ></strong
              >
            </div>
            <div
              :class="`total-resume readable ${step < 4 ? 'float-total' : ''}`"
            >
              <div class="total fw-700 mb-2">Total</div>
              <span
                class="price"
                style="font-size: 28px; font-weight: bold; color: #000"
                >{{ getTotalString }}</span
              >
            </div>
          </div>
          <div class="mt-auto pb-7 btns-sticky" v-if="step == 4">
            <v-btn
              block
              :disabled="step < 4 || loading"
              :loading="loading"
              @click="$emit('goToPay')"
              depressed
              rounded
              color="primary"
              class="mb-2 readable"
              >{{ permitFree ? btnRegister[lang] : btnPay[lang] }}</v-btn
            >
            <v-btn
              @click="$store.dispatch('destroyAdvance')"
              block
              depressed
              rounded
              text
              color="tertiary"
              class="readable"
              >{{ permitFree ? cancelRegistry[lang] : cancelBtn[lang] }}</v-btn
            >
          </div>
        </div>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import { imgService } from "@/libs/media";
import { formatPE } from "@/libs/price";
import { mapGetters } from "vuex";
import CardTicketInfo from "./CardTicketInfo.vue";
import Price from "./Price.vue";
export default {
  props: {
    loading: Boolean
  },
  components: {
    Price,
    CardTicketInfo
  },
  data() {
    return {
      showMoreInfo: {},
      title: {
        en: "Summary of purchase",
        es: "Resumen de la compra"
      },
      categoryTitle: {
        en: "Category selected",
        es: "Categoría seleccionada"
      },
      dateTitle: {
        en: "Date selected",
        es: "Fecha seleccionada"
      },
      articlesTitle: {
        en: "Articles selected",
        es: "Artículos seleccionados"
      },
      peopleDescription: {
        en: "Capacity",
        es: "Aforo"
      },
      btnPay: {
        en: "Pay",
        es: "Pagar"
      },
      btnRegister: {
        en: "Finalize",
        es: "Finalizar"
      },
      cancelBtn: {
        en: "Cancel purchase",
        es: "Cancelar compra"
      },
      cancelRegistry: {
        en: "Cancel registry",
        es: "Cancelar registro"
      }
    };
  },
  computed: {
    ...mapGetters({
      park: "getCurrentParkByDomain",
      step: "getStep",
      lang: "getLanguage",
      total: "getArticlesTotal",
      payload: "getFinalPayload",
      articles: "getArticlesSelected",
      tickets: "getTotalTickets",
      people: "getCurrentTotal",
      permitFree: "validateFree",
      haveAditionalProccess: "haveAditionalProccess",
      getScheduleSelected: "getScheduleSelected",
      getIfGlobalAforo: "getIfGlobalAforo",
      isForo: "contieneIdForo"
    }),
    getTotalString() {
      return formatPE(this.total);
    }
  },
  methods: {
    getImg(filename) {
      return imgService(filename);
    },
    openInfoTicket(showKey) {
      this.showMoreInfo[showKey] = !this.showMoreInfo[showKey];
    }
  }
};
</script>

<style lang="scss">
.card-resume {
  @media screen and (min-width: 960px) {
    position: fixed;
    top: 85px;
    height: calc(100vh - 85px);
  }
  @media screen and (max-width: 959px) {
    .img-resume {
      display: none;
    }
    box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 20%),
      0px 0px 0px 0px rgb(0 0 0 / 14%), 0px 0px 0px 0px rgb(0 0 0 / 12%) !important;
  }
}
.item-selected {
  display: flex;
  flex-flow: column nowrap;
}
.total-resume {
  .total {
    font-size: 20px;
    font-weight: 700;
    color: var(--color-pink);
  }
  padding: 20px 0;
}
.tickets-list {
  //max-height: 10vh;
  overflow-y: auto;
}

.btns-sticky {
  position: sticky;
  padding: 18px 0 0 0;
  bottom: 0;
  background: linear-gradient(
    0deg,
    hsla(0, 0%, 100%, 0.9139005944) 70%,
    hsla(0, 0%, 100%, 0)
  );
}
</style>
