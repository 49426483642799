<template>
  <v-dialog
    :width="width ? width : '1200'"
    scrollable
    persistent
    v-model="dialog"
  >
    <v-card color="#fff" elevation="0">
      <v-toolbar flat>
        <v-toolbar-title>
          <v-icon v-if="icon" color="tertiary">{{ icon }}</v-icon>
          <span class="title-dialog ml-2">{{ title }}</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('closeDialog')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text style="max-height: 88vh" class="pt-5">
        <slot name="content-dialog"> </slot>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: String,
    icon: String,
    dialog: Boolean,
    width: String,
  },
};
</script>

<style lang="scss">
.title-dialog {
  font-family: $primary-font;
  font-size: 15.5px;
  font-weight: 600;
}
</style>