<template>
  <v-expansion-panels accordion focusable class="elevation-0" v-model="panel">
    <v-expansion-panel v-for="(item, i) in faqs" :key="i">
      <v-expansion-panel-header>
        <span class="text-medium fw-600"> {{ item.PREGUNTA }} </span>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div class="pt-5">
          <span class="text-medium fw-400 pre-wrapp" v-html="item.RESPUESTA"></span>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import {  mapGetters } from "vuex"
export default {
  computed: {
    ...mapGetters({
      faqs: "getFAQs"
    })
  },
  data() {
    return {
      panel: [1,0]
    }
  }
};
</script>

<style>
</style>