<template>
  <v-card
    v-if="category"
    class="card__category   mx-auto my-2"
    :class="{ 'card__category--active': isActive }"
    max-width="260"
    min-height="270"
    height="100%"
    @click="$emit('selectCard', category)"
  >
    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

    <v-img
      class="card__category__img"
      height="200"
      :src="loadImg(category.URLFOTO)"
    >
      <span v-if="category.FECHA" class="card__category__img--date">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 24 24"
        >
          <path
            d="M17 1c0-.552-.447-1-1-1s-1 .448-1 1v2c0 .552.447 1 1 1s1-.448 1-1v-2zm-12 2c0 .552-.447 1-1 1s-1-.448-1-1v-2c0-.552.447-1 1-1s1 .448 1 1v2zm13 5v10h-16v-10h16zm2-6h-2v1c0 1.103-.897 2-2 2s-2-.897-2-2v-1h-8v1c0 1.103-.897 2-2 2s-2-.897-2-2v-1h-2v18h20v-18zm4 3v19h-22v-2h20v-17h2zm-17 7h-2v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2zm-8 4h-2v-2h2v2zm4 0h-2v-2h2v2zm4 0h-2v-2h2v2z"
          />
        </svg>
        <small>{{ category.FECHA }}</small>
      </span>
    </v-img>

    <v-card-title class="card__category__title">{{
      category.DESCRIPCION
    }}</v-card-title>

    <v-card-text> </v-card-text>

    <!-- <v-divider class="mx-4"></v-divider> -->

    <v-card-actions class="card__category__actions">
      <span class="card__category__actions--price">
        <small v-if="category.PRECIOBASE != '0.00'" :style="{ fontSize: '11px', fontWeigth: 400 }"
          >{{ price.from[lang] }} </small
        ><strong v-if="category.PRECIOBASE == '0.00'">{{
          price.free[lang]
        }}</strong>
        <strong v-else> S/ {{ category.PRECIOBASE }}</strong>
      </span>
      <!-- <span class="card__category__actions--date">
        
      </span> -->
      <span>
        <svg
          v-if="isActive"
          width="26"
          height="26"
          clip-rule="evenodd"
          fill-rule="evenodd"
          stroke-linejoin="round"
          stroke-miterlimit="2"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m11.998 2.005c5.517 0 9.997 4.48 9.997 9.997 0 5.518-4.48 9.998-9.997 9.998-5.518 0-9.998-4.48-9.998-9.998 0-5.517 4.48-9.997 9.998-9.997zm-5.049 10.386 3.851 3.43c.142.128.321.19.499.19.202 0 .405-.081.552-.242l5.953-6.509c.131-.143.196-.323.196-.502 0-.41-.331-.747-.748-.747-.204 0-.405.082-.554.243l-5.453 5.962-3.298-2.938c-.144-.127-.321-.19-.499-.19-.415 0-.748.335-.748.746 0 .205.084.409.249.557z"
            fill-rule="nonzero"
            fill="var(--color-primary-1)"
          />
        </svg>
      </span>
      <!-- <v-btn color="deep-purple lighten-2" text @click="() => {}">
        Elegir
      </v-btn> -->
    </v-card-actions>
  </v-card>
</template>

<script>
import { imgServicePark } from "@/libs/media";
import { mapGetters } from "vuex";

export default {
  props: {
    category: {
      type: Object,
      default: () => {},
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      price: {
        from: {
          en: "From",
          es: "Desde",
        },
        free: {
          en: "Free",
          es: "Gratis",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      lang: "getLanguage",
    }),
  },
  methods: {
    loadImg(src) {
      console.log(imgServicePark(src));
      return imgServicePark(src);
    },
  },
};
</script>

<style lang="scss">
.card__category {
  position: relative;
  padding-bottom: 15px;
  border: 2px solid transparent;

  &:hover {
    // border: 2px solid var(--color-primary-4);
    box-shadow: 1px 1px 16px -5px rgba(0, 0, 0, 0.75) !important;
  }

  &--active {
    border: 2px solid var(--color-primary-1) !important;
    box-shadow: 1px 1px 16px -5px rgba(0, 0, 0, 0.75) !important;
    border-radius: 4px;
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2 !important;
    border-radius: 4px !important;
  }
  cursor: pointer;
  .card__category__title {
    font-size: 14px !important;
    line-height: 1.2 !important;
  }
  .card__category__actions {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-right: 16px;
    padding-left: 16px;
    z-index: 1;
    &--price {
      strong {
        color: var(--color-primary-3);
      }
    }
  }
  .card__category__img {
    position: relative;
    &--date {
      position: absolute;
      bottom: 6px;
      left: 6px;
      padding: 2px 6px;
      border-radius: 8px;
      background: white;
      display: flex;
      align-items: center;
      gap: 6px;
    }
  }
}
</style>
