<template>
  <v-card elevation="0" class="card-resume card-stepper scroll-design mh-100">
    <div class="link-to-return">
      <!-- <a :href="$store.state.baseURL" class="text-no-decoration" :aria-label="titleBackLink[lang]">
        <v-icon small color="tertiary">arrow_back_ios</v-icon>
        <span class="fw-700">{{ titleBackLink[lang] }}</span>
      </a> -->
      <router-link to="/" class="text-no-decoration" :aria-label="titleBackLink[lang]">
        <v-icon small color="tertiary">arrow_back_ios</v-icon>
        <span class="fw-700">{{ titleBackLink[lang] }}</span>
      </router-link>
    </div>
    <Timer></Timer>
    <v-stepper class="elevation-0 vertical-step" v-model="step" vertical>
      
      <v-stepper-step :complete="step > 1" step="1" class="readable">
        {{ steps.step1.title[lang] }}
        <small>{{ steps.step1.subtitle[lang] }}</small>
      </v-stepper-step>

      <v-stepper-content step="1" style="height: 10vh"></v-stepper-content>

      <v-stepper-step :complete="step > 2" step="2" class="readable">
        {{ steps.step2.title[lang] }}
        <small>{{ steps.step2.subtitle[lang] }}</small>
      </v-stepper-step>

      <v-stepper-content step="2" style="height: 10vh"></v-stepper-content>

      <v-stepper-step
        v-if="park && park.IDPROCESOADICIONAL != '0' && payload.category"
        :complete="step > 3"
        step="3"
        class="readable"
      >
        {{ payload.category.GRUPOCATEGORIA }}
        <!-- <small>{{ steps.step3.subtitle[lang] }}</small> -->
      </v-stepper-step>

      <v-stepper-content
        v-if="park && park.IDPROCESOADICIONAL != '0' && payload.category"
        step="3"
        style="height: 10vh"
      ></v-stepper-content>

      <v-stepper-step :complete="step > 4" step="4" class="readable">
        {{ permitFree ? steps.step4.titleFree[lang] : steps.step4.title[lang] }}
          <small>{{ permitFree ? steps.step4.subtitleFree[lang] : steps.step4.subtitle[lang] }}</small>
      </v-stepper-step>

      <v-stepper-content step="4" style="height: 10vh"></v-stepper-content>
    </v-stepper>

    <!-- Horizontal (mobile) -->
    <v-stepper alt-labels class="mt-5 horizontal-step">
      <v-stepper-header>
        <v-stepper-step :complete="step > 1" step="1" color="primary"  class="readable">
          {{ steps.step1.title[lang] }}
          <small>{{ steps.step1.subtitle[lang] }}</small>
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="step > 2" step="2" color="primary"  class="readable">
          {{ steps.step2.title[lang] }}
          <small>{{ steps.step2.subtitle[lang] }}</small>
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
          v-if="park && park.IDPROCESOADICIONAL != '0' && payload.category"
          :complete="step > 3"
          step="3"
          color="primary"
           class="readable"
        >
          {{ payload.category.GRUPOCATEGORIA }}
          <!-- <small>{{ steps.step3.subtitle[lang] }}</small> -->
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step :complete="step > 4" step="4" color="primary"  class="readable">
          {{ permitFree ? steps.step4.titleFree[lang] : steps.step4.title[lang] }}
          <small>{{ permitFree ? steps.step4.subtitleFree[lang] : steps.step4.subtitle[lang] }}</small>
        </v-stepper-step>
      </v-stepper-header>
    </v-stepper>

    <div class="quiz-btn d-flex justify-content-center py-4">
      <button
        class="faq-button text-medium fw-600 readable"
        type="button"
        @click="dialog = true"
        :aria-label="title[lang]"
        :disabled="park.AYUDA == '0'"
      >
        <span class="mr-1">{{ title[lang] }}</span>
        <v-icon>quiz</v-icon>
      </button>
    </div>

    <Dialog
      :dialog="dialog"
      @closeDialog="dialog = false"
      width="700"
      :title="title[lang]"
      icon="quiz"
    >
      <template v-slot:content-dialog>
        <FAQ class="readable" />
      </template>
    </Dialog>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import Dialog from "@/components/Dialog";
import FAQ from "@/views/subdomain/components/FAQ.vue";
import Timer from "./Timer.vue"
export default {
  components: {
    FAQ,
    Dialog,
    Timer
  },
  data() {
    return {
      dialog: false,
      title: {
        en: "FAQs",
        es: "Preguntas frecuentes",
      },
      titleBackLink: {
        en: `Go to Home`,
        es: `Ir al Inicio`
      }
    };
  },
  computed: {
    ...mapGetters({
      park: "getCurrentParkByDomain",
      payload: "getFinalPayload",
      step: "getStep",
      lang: "getLanguage",
      steps: "getStepsData",
      permitFree: "validateFree"
    }),
  },
  watch: {
    step(val) {
      this.steperChange()
    }
  },
  methods: {
    steperChange() {
      try {
        document.querySelector(".v-main__wrap").scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        })
      } catch (error) {}
    }
  }
};
</script>

<style lang="scss">
.link-to-return {
  margin: 20px 0;
  padding: 10px 24px;
  @media screen and (max-width: 600px) {
    margin: 0;
    padding: 5px 0px !important;
  }
}

.faq-button {
  padding: 12px 10px;
  border-radius: 5px;
  display: block;
  background: var(--color-turquoise);
  border: 1px solid var(--color-blue);
  color: #fff;
  width: 100%;
  margin: 0 24px;
  &:disabled {
    border: 1px solid rgba(0, 0, 0, 0.12)  !important;
    background: rgba(0, 0, 0, 0.12) !important;
    color: rgba(0, 0, 0, 0.26) !important;
    i {
      color: rgba(0, 0, 0, 0.26) !important;
    }
  }
  i {
    color: #fff !important;
  }
}
.horizontal-step {
  display: none;
}
@media screen and (min-width: 960px) {
  .card-stepper {
    max-width: 25%;
    /* display: flex;
    flex-flow: column nowrap; */
  }
}

@media screen and (min-width: 1100px) {
  .card-stepper {
    max-width: 22%;
  }
}

@media screen and (min-width: 1200px) {
  .card-stepper {
    max-width: 20%;
  }
}

@media screen and (min-width: 1700px) {
  .card-stepper {
    max-width: 18%;
  }
}
@media screen and (max-width: 959px) {
  .v-stepper__step:first-of-type {
    padding-left: 0 !important;
  }
  .v-stepper__step:last-of-type {
    padding-right: 0 !important;
  }
  .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    display: flex !important;
    margin-left: 5px;
    small {
      display: none;
    }
  }
  .horizontal-step {
    display: block;
  }
  .vertical-step {
    display: none;
  }
}

@media screen and (max-width: 550px) {
  .horizontal-step {
    margin-top: 0 !important;
  }
  .v-stepper__step {
    flex: 1 !important;
  }

  .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    text-align: center;
    font-size: 13px !important;
  }
  .v-stepper:not(.v-stepper--vertical) .v-stepper__label small {
    display: none !important;
  }
}

@media screen and (max-width: 415px) {
  .v-stepper--alt-labels .v-stepper__header .v-divider {
    margin: 24px -67px 0 !important;
  }
  .v-stepper__step {
    padding: 10px !important;
  }
  .v-stepper:not(.v-stepper--vertical) .v-stepper__label {
    font-size: 12px !important;
  }
}

/* .v-stepper__step--active:not(.v-stepper__step--complete)
  .v-stepper__step__step {
  color: transparent !important;
} */
</style>