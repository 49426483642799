<template>
  <v-row v-if="article">
    <v-col v-if="article.SUBOBJETOS" cols="12" lg="12" class="pb-0">
      <v-autocomplete
        :label="finalPayload.category ? finalPayload.category.ETIQUETA1 : ''"
        :rounded="rounded"
        :color="color"
        :dark="dark"
        :background-color="bgColor"
        :disabled="validateDisable"
        :hide-details="details"
        :outlined="outlined"
        :filled="filled"
        :dense="dense"
        :autocomplete="autocomplete"
        :items="article.SUBOBJETOS"
        clearable
        item-value="VALUE"
        item-text="NAME"
        return-object
        v-model="payload.selectedLvl1"
        @change="$store.dispatch('setMaxQuantity'); emitEvent(); $store.dispatch('validateStock', { article: payload.selectedLvl1, parentArticle: payload})"
      >
      </v-autocomplete>
    </v-col>
    <v-col v-if="payload.selectedLvl1 && payload.selectedLvl1.SUBOBJETOS" cols="12" lg="12" class="pb-0">
      <v-autocomplete
        :label="finalPayload.category ? finalPayload.category.ETIQUETA2 : ''"
        :rounded="rounded"
        :color="color"
        :dark="dark"
        :background-color="bgColor"

        :disabled="validateDisable"
        :hide-details="details"
        :outlined="outlined"
        :filled="filled"
        :dense="dense"
        :autocomplete="autocomplete"
        :items="payload.selectedLvl1 && payload.selectedLvl1.SUBOBJETOS ? payload.selectedLvl1.SUBOBJETOS : []"
        clearable
        item-value="VALUE"
        item-text="NAME"
        return-object
        v-model="payload.selectedLvl2"
        @change="$store.dispatch('setMaxQuantity'); emitEvent(); $store.dispatch('validateStock', { article: payload.selectedLvl2, parentArticle: payload})"
      >
      </v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
import baseConfig from "@/components/forms/baseConfig";
import {mapGetters} from "vuex";
export default {
  props: {
    article: Object,
  },
  data() {
    return {
      ...baseConfig,
      dense: true,
      payload: this.article,
    };
  },
  computed: {
    ...mapGetters( {
      finalPayload: "getFinalPayload"
    }),
    validateDisable() {
      if(!this.payload) return false;
      switch(this.payload.LEVELS) {
        case 2: 
          return this.payload.selectedLvl1 &&  this.payload.CANTIDADSELECCIONADA > 0 ? true : false;
        case 3:
          return this.payload.selectedLvl1 && this.payload.selectedLvl2 &&  this.payload.CANTIDADSELECCIONADA > 0 ? true : false;
      }
      return false;
    }
  },
  methods: {
    emitEvent() {
      this.$emit('updateItem')
    }
  }
};
</script>

<style>
</style>