<template>
  <v-stepper-content step="2">
    <v-card class="elevation-0">
      <div class="title-card mb-2 readable">{{ getTitle[lang] }}</div>
      <p class="subtitle-card readable">{{ getSubtitle[lang] }}</p>
      <SwiperDates
        v-if="
          datesList &&
            datesList.length > 0 &&
            payload.category &&
            payload.category.HABILITARCOMPRAANTICIPADA == 0
        "
        :dates="datesList"
      ></SwiperDates>

      <div v-if="isForo">
        <label class="title-card mt-2 mb-1 readable" for="">{{
          schedules.horario[lang]
        }}</label>
        <v-select
          v-model="payload.horarioCategoria"
          :items="horariosCategoria"
          :label="schedules.placeholder[lang]"
          item-text="HORARIO_DESCRIPCION"
          :item-value="(item) => `${item.HORAINICIO}_${item.HORAFIN}`"
          return-object
          required
          :disabled="!payload.dateSelected"
          @change="onChangeSchedule(2)"
        ></v-select>
      </div>

      <TableTicketsResume
        :hasPurchasePreview="payload.category.HABILITARCOMPRAANTICIPADA"
        v-if="articles && articles.length > 0"
        :disabledTable="Boolean(isForo && !payload.horarioCategoria)"
      ></TableTicketsResume>
      
      <div v-else class="d-flex flex-column align-items-center">
        <img
          draggable="false"
          width="300"
          :src="noArticles.img"
          :alt="noArticles.title[lang]"
        />
        <h6 class="text-medium fw-600 text-center readable">
          {{ noArticles.title[lang] }}
        </h6>
        <p class="readable">
          <small class="fw-500 text-center">{{
            noArticles.subtitle[lang]
          }}</small>
        </p>
      </div>

      <small
        v-if="payload.category"
        v-html="payload.category.MENSAJE"
        class="d-flex mt-0 mb-6 d-flex flex-column readable"
      ></small>
    </v-card>
    <div class="d-flex justify-content-end w-100 float-btn-steppers">
      <v-btn
        class="mr-2 readable"
        text
        @click="() => removeStep2()"
        v-if="steps.step2.buttonCancel"
      >
        <v-icon>mdi-chevron-left</v-icon>
        {{ steps.step2.buttonCancel[lang] }}
      </v-btn>
      <v-btn
        :loading="loading"
        class="readable"
        color="primary"
        :disabled="!articles || articles.length == 0 || loading"
        @click="nextStep"
        rounded
      >
        {{ steps.step2.buttonNext[lang] }}
      </v-btn>
    </div>
  </v-stepper-content>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SwiperDates from "./SwiperDates.vue";
import TableTicketsResume from "./TableTicketsResume.vue";

export default {
  components: {
    SwiperDates,
    TableTicketsResume,
  },
  data() {
    return {
      loading: false,
      title: {
        en: "Select your visit date and your articles of interest",
        es: "Selecciona tu fecha de visita y tus artículos de interés",
      },
      subtitle: {
        en:
          "The date is important as well as that the minimum quantity of articles is 1",
        es:
          "La fecha es importante al igual de que la cantidad mínima de artículos sea 1",
      },
      titleAnticipated: {
        en: "Choose the articles of your interest",
        es: "Elige los artículos de tu interés",
      },
      subtitleAnticipated: {
        en:
          "Advance purchase is enabled, the visit date can be selected after registration/purchase",
        es:
          "La compra anticipada está habilitada, la fecha de visita podrá seleccionarse posterior al registro/compra",
      },
      noArticles: {
        img: require("@/assets/img/calendarioups.png"),
        title: {
          en: "There are no scheduled items.",
          es: "No hay articulos programados.",
        },
        subtitle: {
          en: "Please select another date to continue",
          es: "Por favor seleccione otra fecha para continuar",
        },
      },
      schedules: {
        horario: {
          en: "Schedule",
          es: "Horario",
        },
        placeholder: {
          en: "Choose a time",
          es: "Elige un Hora de Función",
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      steps: "getStepsData",
      lang: "getLanguage",
      park: "getCurrentParkByDomain",
      payload: "getFinalPayload",
      datesList: "getDatesList",
      articles: "getArticlesList",
      permitCombination: "validateCombination",
      accTools: "getAccesibilityTool",
      isForo: "contieneIdForo",
      horariosCategoria: "obtenerHorariosCategoria",
    }),
    getTitle() {
      if (!this.payload.category) return {};
      return this.payload.category.HABILITARCOMPRAANTICIPADA == 0
        ? this.title
        : this.titleAnticipated;
    },
    getSubtitle() {
      if (!this.payload.category) return {};
      return this.payload.category.HABILITARCOMPRAANTICIPADA == 0
        ? this.subtitle
        : this.subtitleAnticipated;
    },
  },
  watch: {
    articles() {
      this.accTools.restart(this.lang);
    },
  },
  methods: {
    ...mapActions({
      changeStep: "changeStep",
      validateStep2: "validateStep2",
      removeStep2: "removeStep2",
      getAditionalProccess: "getAditionalProccess",
      fetchSchedules: "fetchSchedules",
      fetchRoutes: "fetchRoutes",
      changeRouteSelected: "changeRouteSelected",
      validateAforo: "validateAforo",
      getSchedulesDependAditionalProccess:
        "getSchedulesDependAditionalProccess",
      generateSnackbarError: "generateSnackbarError",
      getUniqueArticleIdSelected: "getUniqueArticleIdSelected",
      onChangeSchedule: "onChangeSchedule",
    }),
    async nextStep() {
      this.loading = true;
      const val = await this.validateStep2();
      this.loading = false;
      if (!val) return;

      // Proceso adicional
      if (this.park.IDPROCESOADICIONAL != "0" && !this.isForo) {
        let defaultProp = null;

        if (!this.permitCombination) {
          defaultProp = {
            IDARTICULO: await this.getUniqueArticleIdSelected(),
            IDCATEGORIA: this.payload.category
              ? this.payload.category.IDCATEGORIA
              : 0,
            FECHAVISITA: this.payload.dateSelected,
          };
        }

        this.loading = true;
        const res = await this.getSchedulesDependAditionalProccess(defaultProp);
        this.loading = false;

        if (res.error || !res.schedules) {
          // generar mensaje de error
          this.generateSnackbarError({
            en: "Process configuration error",
            es: "Error de configuración de proceso",
          });
          return;
        }

        this.$store.commit("setGlobalAforo", false);

        if (res.routes) {
          this.$store.commit("setRoutes", res.routes);
          this.$store.commit("setRouteSelected", res.routes[0].IDRUTA);
        }

        this.$store.commit("setSchedules", res.schedules);

        if (res.noCategory) {
          this.$store.commit("setGlobalAforo", true);
        }

        if (res.schedules.length == 1 && res.onlyAforo) {
          // Solo para aforo general
          const haveSpace = await this.$store.dispatch(
            "validateAforo",
            res.schedules[0]
          );
          if (haveSpace) {
            this.$store.commit(
              "setSchedule",
              `1-${res.schedules[0].HORAINICIO}-${res.schedules[0].HORAFIN}`
            );
            //this.$store.commit("setGlobalAforo", true);
            this.changeStep(4);
            return;
          } else {
            // no hay aforo disponible
            this.generateSnackbarError({
              en: "There is no capacity available",
              es: "No hay aforo disponible",
            });
            return;
          }
        }

        this.changeStep(3);
      } else {
        // La validación de aforo para la nueva configuración con IDFORO es diferente
        if (this.isForo) {
          // FINALIZADO: Validar aforo de seleccionados
          this.loading = true;
          const errors = await this.$store.dispatch("validarAforoButacasZonas");
          this.loading = false;
          console.log(errors);
          if (errors?.length > 0) {
            // alert(JSON.stringify(errors));
            this.mostrarError({
              errors: errors,
              title: { en: "Capacity not available", es: "Aforo no disponible" },
              subtitle: {
                en: "Dear customer, the capacity of some items has changed.",
                es:
                  "Estimado cliente, la capacidad de algunos artículos ha variado.",
              },
              footer: {
                en: "Please reselect your items.",
                es: "Por favor, vuelva a seleccionar sus artículos.",
              },
            });
            return;
          }
        }

        this.changeStep(4);
        this.loading = false;
      }
    },
    mostrarError({ errors = [], title, subtitle, footer }) {
      this.$store.commit("setErrorDialog", {
        errors: errors,
        title: title,
        subtitle: subtitle,
        footer: footer,
      });
      this.$store.commit("setDialogError", true);
    }
  },
};
</script>

<style></style>
